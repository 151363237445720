<template>
  <div class="home-container">
    <p class="home-title">Psychology Counseling Practice Cases</p>
    <div style="margin-top: 20px">
      <div v-for="(item, index) in dataList" :key="index" class="home-item">
        <div class="home-item-content" @click="goQuestions(item)">
          <img :src="'https://' + item.cover_pic_address"
            style="width: 180px;height: 180px;object-fit: cover;border-radius: 12px">
          <div style="flex: 1;padding-left: 32px;box-sizing: border-box;">
            <div style="display: flex;flex-direction: row;align-items: center">
              <p class="item-case-title">{{ item.title }}</p>
              <p
                :class="item.case_record.finished ? 'item-case-status-ongoing item-case-status-finished' : 'item-case-status-ongoing'">
                {{ item.case_record.finished ? 'Finished' : 'Enter Case' }}</p>
            </div>
            <p class="item-case-intro">{{ item.intro_en }}</p>
            <el-progress :percentage="item.progress" color="#D60034" stroke-width="12"
              style="width: 50%;margin-top: 10px" text-color="#D60034"></el-progress>
          </div>
        </div>
      </div>
    </div>


    <el-dialog :visible.sync="showPay" title="Payment Method" width="450px" top="20%">

      <div style="align-items: center;text-align: left;line-height: 25px;margin-top: -20px;">
        The subscription fee is 20 SGD. The subscription is valid until<br> December 31, 2023.
        Please select your payment method.
        <span></span>
      </div>

      <div style="align-items: center;text-align: center;margin-top: 25px;">
        <el-button size="medium" @click="showPaypal" type="primary">Credit Card</el-button>

        <el-button size="medium" @click="getOrder" type="primary">Alipay</el-button>
      </div>

      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="showPay = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->


    </el-dialog>

    <el-dialog custom-class="myDialog" top="18%" :visible.sync="showOxPay" :close-on-click-modal="false"
      :show-close="false" width="550px">

      <div style="display: flex;width: 520px;height: 205px;margin-top: -15px;margin-left: 13px;">

        <div style="flex: 1;">

          <div style="margin-top:0px;font-size:16px;color: black;">INTERNATIONAL PSYCHOLOGY<br> OLYMPIAD ASSOCIATION
          </div>


          <div style="margin-top:80px;font-size:20px;color: black;">IPsyE Subscription</div>


          <div style="margin-top:22px;font-size:26px;color: black;">SGD {{ total_price }}</div>


        </div>
        <div style="flex: 1;">

          <div style="text-align:center;">

            <!-- <div style="margin-top:30px;margin-bottom:22px;font-size:16px"></div> -->
            <vue-qr :size="191" :margin="0" :auto-color="true"
              logoSrc="https://mainbucket.learningfirst.cn/%E6%94%AF%E4%BB%98-%E6%94%AF%E4%BB%98%E5%AE%9D.png"
              :logoScale="2" :dot-scale="1" :text="payLink" />
            <div style="margin-top:10px;font-size:16px">Please scan with Alipay</div>
            <!-- <div style="margin-top:20px;margin-bottom:50px;font-size:16px">{{ total_price }} SGD</div> -->

          </div>


        </div>

      </div>

    </el-dialog>


  </div>
</template>

<script>

import { getStudentCases, checkApplication, checkApplicationNew, getStripeUrl, getCaseApplicationOrder } from "../api/ipsye";
import { getUserId, isTestUser } from "../utils/store";
import VueQr from 'vue-qr'

export default {
  components: {
    VueQr
  },
  name: "Home",
  data() {
    return {
      dataList: [],
      showPay: false,
      application_id: '',
      payLink: '',
      payTimer: undefined,
      showOxPay: false,
      total_price: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    goQuestions(row) {

      if (isTestUser()) {
        checkApplicationNew(getUserId()).then((res) => {
          let code = res.data.code
          if (code == 0) {
            this.gotoLecture(row)
          } else {
            // this.$message.error('Please purchase ~')
            this.$globalMessage.$emit('showPayDialog')
          }
        })
        return
      }

      checkApplication(getUserId()).then((res) => {
        let code = res.data.code
        if (code == 0) {
          this.gotoLecture(row)
        } else if (code == -2) {
          this.$message.error(res.data.msg)
        } else {

          this.application_id = res.data.data._id.$id
          this.total_price = res.data.data.total_price

          this.$confirm('IPsyE is a simulated psychological counseling system specifically designed to prepare students for IPsyO 2023. After subscribing, you will be able to view all cases and practice.', 'Account Unpaid', {
            confirmButtonText: 'Subscribe Now',
            cancelButtonText: 'Cancel',
            width: "30%",
            type: 'warning'
          }).then(() => {
            this.showPay = true
          }).catch(() => {

          });


        }
      })
    },

    gotoLecture(row) {
      let case_id = row.case_record.case_id
      let case_record_id = row.case_record._id.$id
      this.$router.push({
        path: '/lecture',
        query: {
          case_id: case_id,
          case_record_id: case_record_id
        }
      })
    },

    getOrder() {

      this.showPay = false;
      // //前往缴费
      getCaseApplicationOrder(this.application_id).then((res) => {

        if (res.data && res.data.data.qrUrl) {
          this.payLink = res.data.data.qrUrl
          this.showOxPay = true
          this.startInternalTimer()
        }
      })
    },
    startInternalTimer() {
      this.payTimer = setInterval(() => {
        checkApplication(getUserId()).then((res) => {
          if (res.data.code == 0) {
            let result = res.data.data;
            if (result.application_status == '已缴费') {
              this.fetchData()
              this.showOxPay = false;
              clearInterval(this.payTimer);
              this.payTimer = null;
              this.$message.success('Pay Success');
            }
          }
        })
      }, 1 * 1000 * 2);
    },

    showPaypal() {
      this.showPay = false;


      // console.log(123123,this.application_id)

      // return
      if (this.application_id == '') {
        return
      }

      getStripeUrl(this.application_id).then(res => {
        if (res.data.code === 0) {
          window.open(res.data.data, '_blank')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    fetchData() {
      this.dataList = []
      getStudentCases(getUserId()).then((res) => {
        let result = res.data.data
        result.map(item => {
          let totalLength = item.question_ids.length
          if (totalLength > 0) {
            let unansweredIds = item.case_record.unanswered_ids.length
            let progress = parseInt((parseFloat((totalLength - unansweredIds)) / totalLength) * 100)
            item.progress = progress
          } else {
            item.progress = 0
          }

          this.dataList.push(item)
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.home-item-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-image: url("../assets/home_item_bg.png");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
}

.item-case-intro {
  margin-top: 15px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #666666;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


.item-case-status-ongoing {
  text-align: center;
  padding: 0px 20px;
  height: 44px;
  line-height: 44px;
  background: #D60034;
  border-radius: 20px;
  font-size: 20px;
  font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #FFFFFF;
}

.item-case-status-finished {
  background: #00D6BD;
}

.item-case-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #333333;
}


.home-container {
  padding: 40px 120px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  height: 90vh;
  background-color: #F8F8F8
}

.home-title {
  font-size: 24px;
  font-family: Alibaba PuHuiTi 2.0-85 Bold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #333333;
}

.home-item {
  margin-bottom: 20px;
  padding: 10px 32px;
  box-sizing: border-box;
  width: 100%;
  max-height: 250px;
}
</style>
